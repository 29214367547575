// redux/types.js

export const FETCH_MUSIC_SUCCESS = 'FETCH_MUSIC_SUCCESS';
export const FETCH_MUSIC_FAILURE = 'FETCH_MUSIC_FAILURE';
export const FETCH_MUSIC_REQUEST = 'FETCH_MUSIC_REQUEST';

// New action type for adding a song
export const ADD_SONG = 'ADD_SONG';

// Music PLayer
export const SHOW_MUSIC_PLAYER = 'SHOW_MUSIC_PLAYER';
export const HIDE_MUSIC_PLAYER = 'HIDE_MUSIC_PLAYER';


export const SET_LOADING_SONG_GENERATION = 'SET_LOADING_SONG_GENERATION';


// types.js
export const FETCH_EXPLORE_MUSIC_REQUEST = 'FETCH_EXPLORE_MUSIC_REQUEST';
export const FETCH_EXPLORE_MUSIC_SUCCESS = 'FETCH_EXPLORE_MUSIC_SUCCESS';
export const FETCH_EXPLORE_MUSIC_FAILURE = 'FETCH_EXPLORE_MUSIC_FAILURE';
export const ADD_EXPLORE_SONG = 'ADD_EXPLORE_SONG';
export const SHOW_EXPLORE_MUSIC_PLAYER = 'SHOW_EXPLORE_MUSIC_PLAYER';
export const HIDE_EXPLORE_MUSIC_PLAYER = 'HIDE_EXPLORE_MUSIC_PLAYER';
export const SET_LOADING_EXPLORE_SONG_GENERATION = 'SET_LOADING_EXPLORE_SONG_GENERATION';

